.device-emulator-container {
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    width: 100%;
}

.mobile-chrome {
    background: url('../assets/IPhone7.png');
    background-repeat: no-repeat;
    background-size: 310px 600px;
    height: 600px;
    width: 310px;
    overflow: hidden;
}

.mobile-frame {
    position: relative;
    top: 70px;
    left: 18px;
    width: calc(310px - 37px);
    height: calc(600px - 140px);
    border: none;
    border-radius: 5px;
}

.tab-chrome {
    background: url('../assets/ipad.jpg');
    background-repeat: no-repeat;
    background-size: 450px 600px;
    width: 450px;
    height: 600px;
    border-radius: 30px;
    overflow: hidden;
}

.tab-frame {
    position: relative;
    top: 60px;
    left: 22px;
    width: 405px;
    height: 480px;
    border: none;
    border-radius: 5px;
}

.without-chrome {
    background: none;
    position: relative;
    top: -60px;
}
.without-chrome .frame {
    box-shadow: 1px 2px 2px 2px lightgrey;
}

button:focus {
    outline: 0;
}

.button-group {
    margin-bottom: 10px;
    z-index: 1000;
}

.icon-button {
    height: 40px;
    width: 40px;
    padding: 7px 5px 5px 5px;
    border-radius: 50%;
    margin: 5px;
    border: none;
    background-color: transparent;
}
.icon-button img.rotate {
    width: 80%;
}

.icon-button:hover {
    color: gray;
    background: rgba(0, 0, 0, 0.08);;
}

.chrome-rotate {
    -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transition: transform 1s;
    transition: transform 1s;
}

.mobile-frame-rotate {
    -ms-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    width: 460px;
    height: 273px;
    position: relative;
    left: -75px;
    top: 165px;
}

.tab-frame-rotate {
    -ms-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    width: 480px;
    height: 405px;
    position: relative;
    left: -13px;
    top: 99px;
}

.mobile-reposition {
    position: relative;
    left: -150px;
    top: -150px;
}

.tab-reposition {
    position: relative;
    top: -70px;
    left: -70px;
}

.tab-reposition--without-chrome {
    position: relative;
    top: -90px;
    left: -20px;
}

.mobile-reposition--without-chrome {
    position: relative;
    left: -90px;
    top: -160px;
}
